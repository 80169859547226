import React from "react"

export const SuperLawyers = () => {
  React.useEffect(() => {
    for (
      var t = document.querySelectorAll("[data-slbadge]"), n = 0;
      n < t.length;
      n++
    )
      init(t[n])
  })

  const init = function (e) {
    var t = getVersion(e),
      n =
        "https://www.superlawyers.com/static/sl-badge/" +
        t +
        "/sl_badge_build.min.js",
      a = "slbadge_link_" + t

    if (document.getElementById(a)) {
      const element = document.getElementById(a)
      element.parentNode.removeChild(element)
    }

    var d = document.createElement("script")

    d.id = a
    d.src = n
    document.body.appendChild(d)
  }

  const getVersion = e => {
    var t = e.getAttribute("data-slbadge")
    return t.substring(0, t.indexOf("-"))
  }

  return null
}
